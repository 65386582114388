<template>
  <div
    class="related"
    v-if="visible"
  >
    <div v-if="relatedArticles" class="mb-3">
      <h2>
        Lees ook
      </h2>

      <media-item
        v-for="snack in relatedArticles"
        class="mb-2 mb-md-3"
        :media-item="snack.mediaItem ? snack.mediaItem : null"
        :key="snack.id"
        :url="snack.translation.url"
        :title="snack.translation.title"
        :unread="true"
        :description="snack.translation.description"
      />
    </div>

    <div v-if="relatedMilestones">
      <h2>
        Openstaande mijlpalen
      </h2>

      <milestones :show-completed-milestones="false" />
    </div>
  </div>
</template>

<script>
import MediaItem from './MediaItem.vue';
import Milestones from './Milestones.vue';

export default {
  props: {
    activeSnacks: Array,
    activeMilestones: Object,
  },

  components: {
    MediaItem,
    Milestones,
  },

  computed: {
    visible() {
      if (!this.relatedArticles && !this.relatedMilestones) {
        return false;
      }

      return true;
    },

    relatedMilestones() {
      if (!this.activeMilestones) {
        return null;
      }

      return this.activeMilestones;
    },

    relatedArticles() {
      if (!this.activeSnacks || !this.activeSnacks.length) {
        return null;
      }

      return this.activeSnacks.slice(0, 2);
    },
  },
};
</script>

<style scoped>
.related {
  background-color: var(--mute-pink);
  padding: 1rem;
  margin: 0 -1rem -1rem;
}

@media (min-width: 768px) {
  .related {
    margin: 0 -2rem -2rem;
  }
}
</style>
