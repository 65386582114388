<template>
  <Button :show-arrow="false">
    <slot />
  </Button>
</template>

<script>
import Button from './Button.vue';

export default {
  components: {
    Button,
  },
};
</script>
