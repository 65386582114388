<template>
  <template v-if="milestone">
    <div
      class="mb-3"
      v-if="milestone.type === 'check'"
    >
      <Button
        classes="btn--full-width mb-2"
        :show-arrow="false"
        @click="onMilestoneComplete"
        :disabled="markMilestoneCompleteLoadingState === 'loading' || milestoneCompleted"
      >
        {{ milestoneCompleted ? 'Afgerond' : 'Afronden' }}
      </Button>

      <Button
        classes="btn--full-width mt-2"
        type="default"
        :show-arrow="false"
        @click="onMilestoneSkip"
        :disabled="
          markMilestoneSkippedLoadingState === 'loading' || milestoneSkipped || milestoneCompleted
        "
      >
        {{ milestoneSkipped ? 'Overgeslagen' : 'Overslaan' }}
      </Button>
    </div>

    <div v-if="milestone.type === 'checklist'">
      <div
        v-for="(checklistItem, checklistItemIndex) in milestone.checklist_items"
        :key="checklistItemIndex"
      >
        <div class="d-flex align-items-center">
          <div class="checklist-checkbox mt-1">
            <input
              :id="`checklist-checkbox-${checklistItemIndex}`"
              type="checkbox"
              class="checklist-checkbox__input"
              v-model="checklistItem.is_complete"
              tabindex="-1"
              @change="() => onChecklistItemChange()"
            >

            <label
              class="checklist-checkbox__label"
              :for="`checklist-checkbox-${checklistItemIndex}`"
            >
              {{ checklistItem.title }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="milestone.type === 'photo'">
      <photo-upload
        :completed="completed"
        :completed-media-url="completedMediaUrl"
        :image-upload-props="{
          milestoneId: milestone.id,
          userId: user.user.id,
          petId: user.active_pet.id,
          hasOverlay: milestone.has_image_overlay,
        }"
        @uploaded="onImageUploaded"
      >
        <button
          class="btn mt-2"
          type="button"
          @click="onMilestoneSkip"
          :disabled="(
            markMilestoneSkippedLoadingState === 'loading'
            || milestoneSkipped
            || milestoneCompleted
          )"
        >
          {{ milestoneSkipped ? 'Overgeslagen' : 'Overslaan' }}
        </button>
      </photo-upload>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from './Button.vue';
import PhotoUpload from './PhotoUpload.vue';

export default {
  props: {
    milestone: {
      type: Object,
      required: true,
    },

    completed: {
      type: Boolean,
      default: false,
    },

    completedMediaUrl: {
      type: [String, null],
      default: null,
    },
  },

  components: {
    PhotoUpload,
    Button,
  },

  computed: {
    ...mapGetters([
      'markMilestoneCompleteLoadingState',
      'markMilestoneSkippedLoadingState',
      'activePetType',
      'user',
    ]),

    milestoneCompleted() {
      return this.milestone?.completed;
    },

    milestoneCompletedMediaUrl() {
      return this.milestone?.completed_milestone_media?.url;
    },

    milestoneSkipped() {
      return this.milestone?.skipped;
    },
  },

  methods: {
    onMilestoneComplete() {
      this.markComplete();
    },

    onMilestoneSkip() {
      this.markSkipped();
    },

    onImageUploaded() {
      this.$emit('milestone-complete');
      this.redirectToSuccess();
    },

    onChecklistItemChange() {
      this.$store.dispatch('updateChecklist', [this.milestone.id, this.milestone.checklist_items]);
    },

    markSkipped() {
      return this.$store.dispatch('markMilestoneSkipped', this.milestone.id)
        .then(() => {
          this.$emit('milestone-skipped');
          this.redirectToMilestones();
        });
    },

    markComplete() {
      return this.$store.dispatch('markMilestoneComplete', this.milestone.id)
        .then(() => {
          this.$emit('milestone-complete');
          this.redirectToSuccess();
        });
    },

    redirectToSuccess() {
      this.$router.push({
        name: 'MilestoneComplete',
        params: { slug: this.milestone.translation.slug },
      });
    },

    redirectToMilestones() {
      this.$router.push({
        name: 'Pet',
      });
    },
  },
};
</script>

<style>
.checklist-checkbox__input {
  opacity: 0;
  position: absolute;
}

.checklist-checkbox__label {
  min-height: 2.328125rem;
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin-right: .5rem;
  font-size: 1rem;
  padding-top: .25rem;
  padding-left: 3rem;
}

.checklist-checkbox__label:before {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 2.328125rem;
  height: 2.328125rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 50%;
}

.checklist-checkbox__label:after {
  content: '';
  top: 0;
  left: 0;
  width: 2.328125rem;
  height: 2.328125rem;
  position: absolute;
  background-size: 18px auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ced4da' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
}

.checklist-checkbox__input:checked + .checklist-checkbox__label:before {
  background-color: green;
  border-color: green;
}

.checklist-checkbox__input:checked + .checklist-checkbox__label:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E%0A");
}
</style>
