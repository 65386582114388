<template>
  <article class="media-item" @click="onClick">
    <div class="media-item__highlighted" v-if="highlighted">
      <span class="sr-only">Uitgelicht</span>
    </div>

    <div class="media-item__thumb" v-if="mediaItem">
      <img
        :src="mediaItem.url"
        alt="mediaItem.alt"
        loading="lazy"
        width="640"
        height="422"
      >
    </div>

    <div v-if="label" class="media-item__label">
      {{ label }}
    </div>

    <h3 class="media-item__heading">
      <router-link :to="url">
        <span class="media-item__heading-unread" v-if="unread">
          <span class="visually-hidden">Ongelezen</span>
        </span>
        {{ title }}
      </router-link>
    </h3>

    <div v-if="description" v-html="description" class="media-item__content">
    </div>
  </article>
</template>

<script>
export default {
  props: {
    mediaItem: {
      type: Object,
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
    },

    url: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    unread: {
      type: Boolean,
      default: false,
    },

    fullyClickable: {
      type: Boolean,
      default: true,
    },

    highlighted: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      if (this.fullyClickable) {
        this.$router.push({
          path: this.url,
        });
      }
    },
  },
};
</script>

<style>
.media-item {
  background-color: #fff;
  border-radius: .875rem;
  padding: 1rem;
  position: relative;
}

.media-item__thumb {
  padding-bottom: 66.666%;
  position: relative;
  overflow: hidden;
  border-radius: .875rem;
  margin-bottom: .875rem;
}

.media-item__thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.media-item__label {
  color: #887e76;
  margin-bottom: .375rem;
}

.media-item__heading {
  margin-bottom: .75rem;
}

.media-item__heading:last-child {
  margin-bottom: 0;
}

.media-item__heading a {
  color: var(--purple);
  text-decoration: none;
  display: flex;
  align-items: flex-start;
}

.media-item__heading-unread {
  background-color: #009ee3;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  margin-right: .5rem;
  margin-top: .25em;
  flex: 0 0 .5rem;
}

.media-item__content > * {
  margin-bottom: 0;
  margin-top: .75rem;
}

.media-item__content > *:first-child {
  margin-top: 0;
}

.media-item__highlighted {
  position: absolute;
  top: -2rem;
  left: -1.75rem;
  width: 4rem;
  height: 3.544rem;
  background: url(../assets/crown.svg) no-repeat center center;
  background-size: 100% auto;
}
</style>
