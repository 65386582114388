<template>
  <div>
    <head-bar :title="null">
      <template v-slot:left>
        <head-bar-link v-if="user" @click="onBackClick">
          <img src="../assets/arrow-left.svg" alt="" aria-hidden="true" />
          Terug
        </head-bar-link>
        <div v-else>
          <img
            class="mt-2"
            src="../assets/kwispelcoach.svg"
            alt="Kwispelcoach logo"
            width="100"
          >
        </div>
      </template>
      <template v-slot:right>
        <milestone-quick-action
          v-if="user && activeArticle && activeArticle.milestone"
          :milestone="activeArticle.milestone"
          @milestone-complete="onMilestoneComplete"
        />
      </template>
    </head-bar>

    <app-layout classes="article">
      <div class="p-2 p-md-3" v-if="activeArticle">
        <div class="article__media mb-2 mb-md-3" v-if="activeArticle.mediaItem">
          <img
            :src="activeArticle.mediaItem.url"
            :alt="activeArticle.mediaItem.alt"
            width="640"
            height="422"
          >
        </div>

        <router-link
          :to="{ name: 'Category', params: { slug: primaryCategory.translation.slug } }"
          class="article__label"
          v-if="primaryCategory"
        >
          <span class="sr-only">Categorie: </span>{{ primaryCategory.translation.title }}
        </router-link>

        <h1 class="article__heading mb-2 mb-md-3">
          {{ activeArticle.translation.title }}
        </h1>

        <div :class="`article__blocks-wrap ${user ? 'is-logged-in' : 'is-anonymous'}`">
          <blocks-renderer :blocks="activeArticle.translation.blocks" />
        </div>

        <article-login-wall v-if="!user" />

        <div class="mb-2">
          <milestone-action
            v-if="user && activeArticle && activeArticle.milestone"
            :milestone="activeArticle.milestone"
            :completed="activeArticle.milestone.completed"
            :completed-media-url="activeArticle.milestone.completedUserMediaItem?.default_url"
            @milestone-complete="onMilestoneComplete"
            @milestone-skipped="onMilestoneSkipped"
          />

          <author-block
            v-if="activeArticle.show_author && activeArticle.author"
            :author="activeArticle.author"
          />
        </div>

        <article-related
          v-if="user"
          :active-snacks="activeSnacks"
          :active-milestones="activeMilestones"
        />
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import BlocksRenderer from '../components/BlocksRenderer.vue';
import AuthorBlock from '../components/AuthorBlock.vue';
import HeadBar from '../components/HeadBar.vue';
import HeadBarLink from '../components/HeadBarLink.vue';
import MilestoneAction from '../components/MilestoneAction.vue';
import MilestoneQuickAction from '../components/MilestoneQuickAction.vue';
import ArticleRelated from '../components/ArticleRelated.vue';
import ArticleLoginWall from '../components/ArticleLoginWall.vue';

export default {
  inject: ['$meta'],

  components: {
    ArticleLoginWall,
    MilestoneQuickAction,
    MilestoneAction,
    HeadBarLink,
    HeadBar,
    BlocksRenderer,
    AppLayout,
    AuthorBlock,
    ArticleRelated,
  },

  created() {
    this.loadArticle();
  },

  unmounted() {
    this.$store.commit('setActiveArticle', null);
  },

  computed: {
    ...mapGetters([
      'activeArticle',
      'articleLoadingState',
      'activeSnacks',
      'activeMilestones',
      'user',
    ]),

    primaryCategory() {
      if (
        !this.activeArticle
        || !this.activeArticle.categories
        || this.activeArticle.categories.length === 0
      ) {
        return null;
      }

      return this.activeArticle.categories[0];
    },
  },

  methods: {
    loadArticle() {
      const { slug } = this.$route.params;

      return this.$store.dispatch('loadArticle', slug)
        .then(() => {
          if (this.activeArticle) {
            const image = this.activeArticle.socialMediaItem && typeof this.activeArticle.socialMediaItem !== 'undefined' && typeof this.activeArticle.socialMediaItem.url !== 'undefined'
              ? this.activeArticle.socialMediaItem.url
              : null;

            this.$meta({
              title: this.activeArticle.translation.meta.title,
              description: this.activeArticle.translation.meta.description,
              robots: this.activeArticle.translation.meta.robots,
              image,
            });
          }

          // Reload user to update active snacks and the article indicator.
          this.$store.dispatch('fetchUser');
        });
    },

    onBackClick() {
      this.$router.go(-1);
    },

    onMilestoneComplete() {
      this.loadArticle();
      this.$store.dispatch('loadMilestones');
    },

    onMilestoneSkipped() {
      this.loadArticle();
      this.$store.dispatch('loadMilestones');
    },
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path && to.name === from.name) {
        this.loadArticle();

        this.$nextTick(() => {
          document.documentElement.scrollTop = 0;
        });
      }
    },
  },
};
</script>

<style>
.app-layout.article {
  background-color: #fff;
  padding-top: 3.5rem;
}

.article__media {
  border-radius: .875rem;
  overflow: hidden;
  padding-bottom: 66.666%;
  position: relative;
}

.article__media img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.article__label {
  color: #887e76;
  margin-bottom: .5rem;
  display: inline-block;
  text-decoration: none;
}

.article__heading {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--purple);
}

.article__blocks-wrap.is-anonymous {
  max-height: 10rem;
  overflow: hidden;
  position: relative;
}

.article__blocks-wrap.is-anonymous:before {
  content: '';
  width: 100%;
  height: 6rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.article__read-more {
  background-color: var(--mute-pink);
  padding: 1rem;
  border-radius: .375rem;
}
</style>
