<template>
  <div class="article__read-more">
    <div class="mb-2">
      Verder lezen? Maak een gratis account aan en ontvang interessante
      informatie over honden en/of katten!
    </div>

    <Button component-type="router-link" :show-arrow="true" :to="{ name: 'Home' }">
      Maak een account aan
    </Button>
  </div>
</template>

<script>
import Button from './Button.vue';

export default {
  components: {
    Button,
  },
};
</script>
