<template>
  <svg
    :height="radius * 2"
    :width="radius * 2"
  >
    <circle
      :stroke="strokeBackgroundColor"
      fill="transparent"
      :stroke-width="strokeWidth"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      :stroke="strokeColor"
      fill="transparent"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="strokeWidth"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
    },

    progress: {
      type: Number,
    },

    strokeWidth: {
      type: Number,
    },

    strokeBackgroundColor: {
      type: String,
      default: '#c5c6c6',
    },

    strokeColor: {
      type: String,
      default: '#9ac426',
    },
  },

  data() {
    const normalizedRadius = this.radius - (this.strokeWidth * 2);
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference,
    };
  },

  computed: {
    strokeDashoffset() {
      return this.circumference - ((this.progress / 100) * this.circumference);
    },
  },
};
</script>

<style scoped>
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
