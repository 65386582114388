<template>
  <ul>
    <template v-for="(milestones, groupKey) in milestoneGroups" :key="groupKey">
      <!-- If the groupKey is not_set we immediately show the milestone -->
      <template v-if="groupKey === 'not_set'">
        <li v-for="milestone in milestones" :key="milestone.id">
          <row-button
            class="mb-2"
            :to="milestone.route"
            :checked="milestone.completed"
          >
            {{ milestone.translation.title }}
          </row-button>
        </li>
      </template>

      <!-- If the groupKey is set we immediately show the group -->
      <template v-else>
        <li v-if="milestoneGroupForId(milestones[0], groupKey)">
          <row-button
            class="mb-2"
            :to="{ name: 'MilestoneGroup', params: { id: groupKey } }"
            :progress="numberOfCompletedMilestones(milestones) / milestones.length"
            :label="`${numberOfCompletedMilestones(milestones)} van ${milestones.length}`"
          >
            {{ milestoneGroupForId(milestones[0], groupKey).translation.title }}
          </row-button>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import RowButton from './RowButton.vue';

export default {
  components: {
    RowButton,
  },

  props: {
    showCompletedMilestones: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters([
      'activeMilestones',
    ]),

    milestoneGroups() {
      const milestoneGroups = {};

      if (!this.activeMilestones) {
        return [];
      }

      Object.keys(this.activeMilestones).forEach((groupKey) => {
        const milestones = this.activeMilestones[groupKey];

        milestoneGroups[groupKey] = milestones
          .filter((milestone) => {
            return this.showCompletedMilestones || !milestone.completed;
          })
          .map((milestone) => {
            const milestoneClone = { ...milestone };

            if (milestoneClone.type === 'weight') {
              milestoneClone.route = { name: 'PetWeight' };
            } else if (milestoneClone.type === 'height') {
              milestoneClone.route = { name: 'PetHeight' };
            } else {
              const routeName = milestoneClone.primary_article
                ? 'Snack'
                : 'Milestone';

              const routeSlug = milestoneClone.primary_article
                ? milestoneClone.primary_article.translation.slug
                : milestoneClone.translation.slug;

              milestoneClone.route = {
                name: routeName,
                params: { slug: routeSlug },
              };
            }

            return milestoneClone;
          });
      });

      return milestoneGroups;
    },
  },

  methods: {
    milestoneGroupForId(milestone, id) {
      const milestoneGroups = milestone.milestone_groups;

      if (typeof milestoneGroups === 'undefined' || !milestoneGroups.length) {
        return null;
      }

      return milestoneGroups.find((group) => group.id === parseInt(id, 10));
    },

    numberOfCompletedMilestones(milestones) {
      return milestones.filter((milestone) => milestone.completed).length;
    },
  },
};
</script>
