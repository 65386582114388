<template>
  <component
    :is="componentType"
    :to="to"
    :type="componentType === 'button' ? buttonType : null"
    :class="`row-button ${paddingLess ? 'row-button--no-padding' : ''}`"
    v-on="componentType === 'button' ? { click: onClick } : {}"
  >
    <progress-circle
      class="row-button__progress"
      v-if="progress !== null"
      :progress="progress * 100"
      :stroke-width="3"
      :radius="22"
    />

    <div v-if="checked !== null" :class="`row-button__check ${checked ? 'is-checked' : ''}`">
      <img v-if="checked" src="../assets/check-white.svg" alt="" class="row-button__check-icon">
    </div>

    <div v-if="icon" class="row-button__front-icon">
      <icon identifier="plus" />
    </div>

    <div class="row-button__text">
      <div v-if="label" class="row-button__label">
        {{ label }}
      </div>
      <div class="row-button__heading">
        <slot />
      </div>
    </div>

    <img src="../assets/arrow-right-grey.svg" class="row-button__icon" alt="" />
  </component>
</template>

<script>
import ProgressCircle from './ProgressCircle.vue';
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
    ProgressCircle,
  },

  props: {
    to: {
      type: [String, Object],
    },

    buttonType: {
      type: String,
      default: 'button',
    },

    label: {
      type: [String, null],
      default: null,
    },

    progress: {
      type: [Number, null],
      default: null,
    },

    checked: {
      type: [Boolean, null],
      default: null,
    },

    icon: {
      type: [String, null],
      default: null,
    },

    paddingLess: {
      type: [Boolean, null],
      default: false,
    },
  },

  computed: {
    componentType() {
      return this.to ? 'router-link' : 'button';
    },
  },

  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style>
.row-button {
  background-color: #fff;
  border: 0;
  padding: .875rem 2.5rem .875rem 1rem;
  width: 100%;
  font-family: var(--default-font-family);
  text-align: left;
  font-size: 1rem;
  border-radius: .875rem;
  position: relative;
  display: flex;
  align-items: center;
}

a.row-button {
  text-decoration: none;
  color: var(--text);
}

.row-button--no-padding {
  padding: 0;
}

.row-button__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.row-button--no-padding .row-button__icon {
  right: 0;
}

.row-button__progress {
  margin: -.375rem .75rem -.375rem -.375rem;
}

.row-button__heading {
  font-weight: 700;
  color: var(--purple);
}

.row-button__label {
  margin-bottom: .25rem;
}

.row-button__check {
  width: 32px;
  height: 32px;
  border: 2px solid var(--secondary);
  border-radius: 50%;
  margin-right: .875rem;
  position: relative;
  flex-shrink: 0;
}

.row-button__check.is-checked {
  background-color: var(--secondary);
}

.row-button__check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
}

.row-button__front-icon {
  width: 2rem;
  height: 2rem;
  background-color: #f2eaf0;
  border-radius: 50%;
  margin-right: .75rem;
  position: relative;
}

.row-button__front-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: .9375rem;
}
</style>
