<template>
  <template v-if="milestone">
    <head-bar-button
      v-if="milestone.type === 'check'"
      @click="onMilestoneComplete"
      :disabled="markMilestoneCompleteLoadingState === 'loading' || milestoneCompleted"
    >
      {{ milestoneCompleted ? 'Afgerond' : 'Afronden' }}
    </head-bar-button>
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import HeadBarButton from './HeadBarButton.vue';

export default {
  props: {
    milestone: {
      type: Object,
      required: true,
    },
  },

  components: {
    HeadBarButton,
  },

  computed: {
    ...mapGetters([
      'markMilestoneCompleteLoadingState',
    ]),

    milestoneCompleted() {
      return this.milestone?.completed;
    },
  },

  methods: {
    onMilestoneComplete() {
      this.$store.dispatch('markMilestoneComplete', this.milestone.id)
        .then(() => {
          this.$emit('milestone-complete');
          this.redirectToSuccess();
        });
    },

    redirectToSuccess() {
      this.$router.push({
        name: 'MilestoneComplete',
        params: { slug: this.milestone.translation.slug },
      });
    },
  },
};
</script>
