<template>
  <div>
    <div class="color-text">
      Deze tip is aangeboden door:
    </div>

    <div class="author">
      <img
        v-if="author.media"
        class="author__image"
        :src="author.media.url"
        :alt="author.media.alt"
      />

      <div class="author__content">
        <strong v-if="author.full_name" class="author__name">{{ author.full_name }}</strong>
        <div v-if="author.function" class="author__function">{{ author.function }}</div>

        <a
          class="author__brand"
          v-if="author.brand && author.brand === 'smolke'"
          href="https://www.smolke.nl/?utm_source=app&utm_medium=app"
          target="_blank"
          rel="nofollow noopener"
        >
          <img src="../assets/brand.svg" alt="Smølke" width="80" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.author {
  display: flex;
  margin-top: .5rem;
}

.author__image {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: .875rem;
  flex: 0 0 4rem;
}

.author__name,
.author__function {
  display: block;
}

.author__name {
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--purple);
  margin-bottom: .125rem;
}

.author__function {
  margin-bottom: .75rem;
}

.author__brand {
  display: inline-block;
  vertical-align: top;
  margin-top: .25rem;
}
</style>
